exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contactenos-tsx": () => import("./../../../src/pages/contactenos.tsx" /* webpackChunkName: "component---src-pages-contactenos-tsx" */),
  "component---src-pages-galeria-tsx": () => import("./../../../src/pages/galeria.tsx" /* webpackChunkName: "component---src-pages-galeria-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("./../../../src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-pagos-tsx": () => import("./../../../src/pages/pagos.tsx" /* webpackChunkName: "component---src-pages-pagos-tsx" */),
  "component---src-pages-servicios-cableado-estructurado-tsx": () => import("./../../../src/pages/servicios/cableado-estructurado.tsx" /* webpackChunkName: "component---src-pages-servicios-cableado-estructurado-tsx" */),
  "component---src-pages-servicios-otros-servicios-tsx": () => import("./../../../src/pages/servicios/otros-servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-otros-servicios-tsx" */),
  "component---src-pages-servicios-redes-electricas-tsx": () => import("./../../../src/pages/servicios/redes-electricas.tsx" /* webpackChunkName: "component---src-pages-servicios-redes-electricas-tsx" */),
  "component---src-pages-servicios-seguridad-tsx": () => import("./../../../src/pages/servicios/seguridad.tsx" /* webpackChunkName: "component---src-pages-servicios-seguridad-tsx" */),
  "component---src-pages-servicios-tsx": () => import("./../../../src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */),
  "component---src-pages-tratamiento-de-datos-tsx": () => import("./../../../src/pages/tratamiento-de-datos.tsx" /* webpackChunkName: "component---src-pages-tratamiento-de-datos-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */)
}

